import React, { useState } from "react";
import imageCompression from "browser-image-compression";
import img from "../Assets/Spinner-3.gif";

export const NormalBgcForm = () => {
  const [isSameAddressChecked, setIsSameAddressChecked] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  /* first form field */

  const [name, setName] = useState("");
  const [fname, setFname] = useState("");
  const [mname, setMname] = useState("");
  const [gender, setGender] = useState("");
  const [date, setDate] = useState("");
  const [email, setEmail] = useState("");
  const [number, setNumber] = useState("");
  const [enumber, setEnumber] = useState("");
  const [companyname, setCompanyName] = useState("");
  const [location, setLocation] = useState("");
  const [client_id, setClientId] = useState("");
  const [blood, setBlood] = useState("");

  const [address1, setAddress1] = useState("");
  const [address2, setAddress2] = useState("");
  const [address3, setAddress3] = useState("");
  const [post, setPost] = useState("");
  const [police, setPolice] = useState("");
  const [district, setDistrict] = useState("");
  const [tehsil, setTehsil] = useState("");
  const [landmark, setLandmark] = useState("");
  const [pincode, setPincode] = useState("");
  const [state, setState] = useState("");

  const [address11, setAddress11] = useState("");
  const [address22, setAddress22] = useState("");
  const [address33, setAddress33] = useState("");
  const [post1, setPost1] = useState("");
  const [police1, setPolice1] = useState("");
  const [district1, setDistrict1] = useState("");
  const [tehsil1, setTehsil1] = useState("");
  const [landmark1, setLandmark1] = useState("");
  const [pincode1, setPincode1] = useState("");
  const [state1, setState1] = useState("");

  /* third form field */
  const [reference_1_name, setReference_1_Name] = useState("");
  const [reference_1_relation, setReference_1_Relation] = useState("");
  const [reference_1_address, setReference_1_Address] = useState("");
  const [reference_1_number, setReference_1_Number] = useState("");

  const [reference_2_name, setReference_2_Name] = useState("");
  const [reference_2_relation, setReference_2_Relation] = useState("");
  const [reference_2_address, setReference_2_Address] = useState("");
  const [reference_2_number, setReference_2_Number] = useState("");

  const [reference_3_name, setReference_3_Name] = useState("");
  const [reference_3_relation, setReference_3_Relation] = useState("");
  const [reference_3_address, setReference_3_Address] = useState("");
  const [reference_3_number, setReference_3_Number] = useState("");

  const [reference_4_name, setReference_4_Name] = useState("");
  const [reference_4_relation, setReference_4_Relation] = useState("");
  const [reference_4_address, setReference_4_Address] = useState("");
  const [reference_4_number, setReference_4_Number] = useState("");

  const [errors , setErrors] = useState({})

  /* forth form field */
  const [aadhar, setAadhar] = useState("");
  const [aadhar_front_side, setAadhar_Front_Side] = useState("");
  const [aadhar_back_side, setAadhar_Back_Side] = useState("");
  const [passport_size_photo, setPassport_Size_Photo] = useState("");
  const [pan_card_number, setPan_Card_Number] = useState("");
  const [driving_license, setDriving_License] = useState("");

  const [candidate, setCandidateId] = useState("");

  const billingFunction = () => {
    setIsSameAddressChecked((prevValue) => !prevValue);
    if (isSameAddressChecked) {
      setAddress11("");
      setAddress22("");
      setAddress33("");
      setPost1("");
      setPolice1("");
      setDistrict1("");
      setTehsil1("");
      setLandmark1("");
      setPincode1("");
      setState1("");
    } else {
      setAddress11(address1);
      setAddress22(address2);
      setAddress33(address3);
      setPost1(post);
      setPolice1(police);
      setDistrict1(district);
      setTehsil1(tehsil);
      setLandmark1(landmark);
      setPincode1(pincode);
      setState1(state);
    }
  };

  const [currentStep, setCurrentStep] = useState(1);

  /*first page next......... */
  const nextStep1 = () => {
    if (
      name &&
      fname &&
      mname &&
      gender &&
      date &&
      email &&
      number &&
      enumber &&
      companyname &&
      location &&
      // client_id &&
      address1 &&
      post &&
      police &&
      district &&
      tehsil &&
      landmark &&
      pincode &&
      state &&
      address11 &&
      post1 &&
      police1 &&
      district1 &&
      tehsil1 &&
      landmark1 &&
      pincode1 &&
      state1
    ) {
      const emailR =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (emailR.test(email)) {
        setCurrentStep((prevStep) => prevStep + 1);
      } else {
        alert("Please enter a valid email address");
      }
    } else {
      alert("Please fill out all required fields");
    }
  };
  /*Second page next......... */
  // const nextStep2 = () => {
  //   if (
  //     reference_1_name &&
  //     reference_1_relation &&
  //     reference_1_number &&
  //     reference_1_address &&
  //     reference_2_name &&
  //     reference_2_relation &&
  //     reference_2_number &&
  //     reference_2_address &&
  //     reference_3_name &&
  //     reference_3_relation &&
  //     reference_3_number &&
  //     reference_3_address &&
  //     reference_4_name &&
  //     reference_4_relation &&
  //     reference_4_number &&
  //     reference_4_address
  //   ) {
  //     setCurrentStep((prevStep) => prevStep + 1);
  //   } else {
  //     alert("Please fill out all required fields");
  //   }
  // };

  const nextStep2 = () => {
    const newErrors = {};
  
    // Check if all fields are filled and valid
    if (!reference_1_name) newErrors.reference_1_name = "Name is required.";
    if (!reference_1_relation) newErrors.reference_1_relation = "Relation is required.";
    if (!reference_1_number) {
      newErrors.reference_1_number = "Contact number is required.";
    } else if (!/^\d{10}$/.test(reference_1_number)) {
      newErrors.reference_1_number = "Enter a valid 10-digit number.";
    }
    if (!reference_1_address) newErrors.reference_1_address = "Address is required.";
  
    if (!reference_2_name) newErrors.reference_2_name = "Name is required.";
    if (!reference_2_relation) newErrors.reference_2_relation = "Relation is required.";
    if (!reference_2_number) {
      newErrors.reference_2_number = "Contact number is required.";
    } else if (!/^\d{10}$/.test(reference_2_number)) {
      newErrors.reference_2_number = "Enter a valid 10-digit number.";
    }
    if (!reference_2_address) newErrors.reference_2_address = "Address is required.";
  
    if (!reference_3_name) newErrors.reference_3_name = "Name is required.";
    if (!reference_3_relation) newErrors.reference_3_relation = "Relation is required.";
    if (!reference_3_number) {
      newErrors.reference_3_number = "Contact number is required.";
    } else if (!/^\d{10}$/.test(reference_3_number)) {
      newErrors.reference_3_number = "Enter a valid 10-digit number.";
    }
    if (!reference_3_address) newErrors.reference_3_address = "Address is required.";
  
    if (!reference_4_name) newErrors.reference_4_name = "Name is required.";
    if (!reference_4_relation) newErrors.reference_4_relation = "Relation is required.";
    if (!reference_4_number) {
      newErrors.reference_4_number = "Contact number is required.";
    } else if (!/^\d{10}$/.test(reference_4_number)) {
      newErrors.reference_4_number = "Enter a valid 10-digit number.";
    }
    if (!reference_4_address) newErrors.reference_4_address = "Address is required.";
  
    // Check for duplicate numbers
    const numbers = [
      reference_1_number,
      reference_2_number,
      reference_3_number,
      reference_4_number,
    ];
    const duplicateNumbers = numbers.filter(
      (number, index) => numbers.indexOf(number) !== index
    );
  
    if (duplicateNumbers.length > 0) {
      newErrors.duplicate = "Duplicate contact numbers are not allowed.";
      alert("Duplicate contact numbers are not allowed."); // Alert message
    }
  
    // Update errors state
    setErrors(newErrors);
  
    // Proceed to the next step if there are no errors
    if (Object.keys(newErrors).length === 0) {
      setCurrentStep((prevStep) => prevStep + 1);
    }
  };
  
  /*Third page next......... */
  const nextStep3 = () => {
    if (
      aadhar &&
      aadhar_front_side &&
      aadhar_back_side &&
      passport_size_photo 
    ) {
      setCurrentStep((prevStep) => prevStep + 1);
    } else {
      alert("Please fill out all required fields");
    }
  };

  /*Fourth page form.... */

  const nextStep4 = (e) => {
    e.preventDefault();

    const selectedTenancy = document.getElementById("tenancy1").value;
    if (!selectedTenancy) {
      alert("Please choose a tenancy option.");
      return;
    }
    if (selectedTenancy === "Rented") {
      if (
        !house_owner_name ||
        !tenancy_address ||
        !tenancy_number ||
        !residing_date
      ) {
        alert("Please fill out all required fields for rented tenancy.");
        return;
      }
    }
    BgcFormSubmit(e);
  };
  const prevStep = () => {
    setCurrentStep((prevStep) => prevStep - 1);
  };

  /*Fifth form field...... */
  const [tenancy_number, setTenancy_Number] = useState("");
  const [house_owner_name, setHouse_Owner_Name] = useState("");
  const [tenancy_address, setTenancy_Address] = useState("");
  const [residing_date, setResiding_date] = useState("");

  const [isOwnHouse, setIsOwnHouse] = useState(false);

  const hideTenancy = () => {
    const selected = document.getElementById("tenancy1").value;
    setIsOwnHouse(selected === "Rented");
  };

  const BgcFormSubmit = async (e) => {
    setIsLoading(true);
    // e.preventDefault();
    const formData = new FormData();

    // formData.append('employmentdata', employmentdata);
    formData.append("full_name", name);
    formData.append("fathers_name", fname);
    formData.append("mothers_name", mname);
    formData.append("gender", gender);
    formData.append("dob", date);
    formData.append("email", email);
    formData.append("contact_number", number);
    formData.append("emergency_number", enumber);
    formData.append("name_of_the_client", companyname);
    formData.append("client_location", location);
    formData.append("Employee_id", client_id);
    formData.append("blood_group", blood);

    // formData.append("current_village_city", address1);
    // formData.append("current_village_city2", address2);
    // formData.append("current_village_city3", address3);
    // formData.append("current_post_office", post);
    // formData.append("current_police_station", police);
    // formData.append("current_district", district);
    // formData.append("current_tehsil", tehsil);
    // formData.append("current_landmark", landmark);
    // formData.append("current_pincode", pincode);
    // formData.append("current_state", state);

    // formData.append("permanent_village_city", address11);
    // formData.append("permanent_village_city2", address22);
    // formData.append("permanent_village_city3", address33);
    // formData.append("permanent_post_office", post1);
    // formData.append("permanent_police_station", police1);
    // formData.append("permanent_district", district1);
    // formData.append("permanent_tehsil", tehsil1);
    // formData.append("permanent_landmark", landmark1);
    // formData.append("permanent_pincode", pincode1);
    // formData.append("permanent_state", state1);

    formData.append("permanent_village_city", address1);
    formData.append("permanent_village_city2", address2);
    formData.append("permanent_village_city3", address3);
    formData.append("permanent_post_office", post);
    formData.append("permanent_police_station", police);
    formData.append("permanent_district", district);
    formData.append("permanent_tehsil", tehsil);
    formData.append("permanent_landmark", landmark);
    formData.append("permanent_pincode", pincode);
    formData.append("permanent_state", state);

    formData.append("current_village_city", address11);
    formData.append("current_village_city2", address22);
    formData.append("current_village_city3", address33);
    formData.append("current_post_office", post1);
    formData.append("current_police_station", police1);
    formData.append("current_district", district1);
    formData.append("current_tehsil", tehsil1);
    formData.append("current_landmark", landmark1);
    formData.append("current_pincode", pincode1);
    formData.append("current_state", state1);
    
    formData.append("ref_name1", reference_1_name);
    formData.append("ref_relation1", reference_1_relation);
    formData.append("ref_contact1", reference_1_number);
    formData.append("ref_address1", reference_1_address);
    formData.append("ref_name2", reference_2_name);
    formData.append("ref_relation2", reference_2_relation);
    formData.append("ref_contact2", reference_2_number);
    formData.append("ref_address2", reference_2_address);
    formData.append("ref_name3", reference_3_name);
    formData.append("ref_relation3", reference_3_relation);
    formData.append("ref_contact3", reference_3_number);
    formData.append("ref_address3", reference_3_address);
    formData.append("ref_name4", reference_4_name);
    formData.append("ref_relation4", reference_4_relation);
    formData.append("ref_contact4", reference_4_number);
    formData.append("ref_address4", reference_4_address);
    formData.append("adhar_number", aadhar);
    formData.append("adhar_frontside", aadhar_front_side);
    formData.append("adhar_backside", aadhar_back_side);
    formData.append("photo", passport_size_photo);
    formData.append("pancard_number", pan_card_number);
    formData.append("driving_license", driving_license);
    // formData.append("employementdata", employementdata);
    formData.append("house_owner_name", house_owner_name);
    formData.append("tenancy_contact", tenancy_number);
    formData.append("address", tenancy_address);
    formData.append("residing_date", residing_date);

    try {
      let response = await fetch(
        "https://humhai.in/background-verification/featured-form/",
        {
          method: "POST",
          body: formData,
        }
      );

      let data = await response.json();
      // console.log(data, "data");
      setCandidateId(data.id);
      // console.log(candidate, "candidate");

      if (data.status === 200) {
        // console.log("data.status", data.status);
        setIsLoading(false);
        // alert(`text: Copy/ScreenShot - Your HH ID: ${data.candidate}`);
        alert(
          `Your form has been successfully submitted. A reference ID will be provided once your data has been checked.`
        );
        clearValue();
        window.location.reload();
      }
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const clearValue = () => {
    setName("");
    setFname("");
    setMname("");
    setGender("");
    setDate("");
    setEmail("");
    setNumber("");
    setEnumber("");
    setCompanyName("");
    setLocation("");
    setClientId("");
    setBlood("");
    setAddress1("");
    setAddress2("");
    setAddress3("");
    setPost("");
    setPolice("");
    setDistrict("");
    setTehsil("");
    setLandmark("");
    setPincode("");
    setState("");
    setAddress11("");
    setAddress22("");
    setAddress33("");
    setPost1("");
    setPolice1("");
    setDistrict1("");
    setTehsil1("");
    setLandmark1("");
    setPincode1("");
    setState1("");
    setReference_1_Name("");
    setReference_1_Relation("");
    setReference_1_Address("");
    setReference_1_Number("");
    setReference_2_Name("");
    setReference_2_Relation("");
    setReference_2_Address("");
    setReference_2_Number("");
    setReference_3_Name("");
    setReference_3_Relation("");
    setReference_3_Address("");
    setReference_3_Number("");
    setReference_4_Name("");
    setReference_4_Relation("");
    setReference_4_Address("");
    setReference_4_Number("");
    setAadhar("");
    setAadhar_Front_Side("");
    setAadhar_Back_Side("");
    setPassport_Size_Photo("");
    setPan_Card_Number("");
    setDriving_License("");
    // setEmployementData("")
    setTenancy_Number("");
    setHouse_Owner_Name("");
    setTenancy_Address("");
    setResiding_date("");
  };

  const handleFileChange = async (event, setStateFunction) => {
    const file = event.target.files[0];
    if (file) {
      try {
        const fileType = file.type;

        if (
          fileType === "application/pdf" ||
          fileType === "image/jpeg" ||
          fileType === "image/png" ||
          fileType === "image/jpg"
        ) {
          if (fileType === "application/pdf") {
            setStateFunction(file);
          } else {
            const options = {
              maxSizeMB: 0.2,
              maxWidthOrHeight: 1920,
              useWebWorker: true,
            };

            const compressedFile = await imageCompression(file, options);
            setStateFunction(compressedFile);
          }
        } else {
          console.error(
            "Unsupported file format. Please upload a PDF, JPG, JPEG, or PNG file."
          );
        }
      } catch (error) {
        console.error("Error processing file:", error);
      }
    }
  };

  return (
    <div>
      <div id="regForm" encType="multipart/form-data">
        <input
          type="hidden"
          name="csrfmiddlewaretoken"
          value="rXPcP9QunvYuPOuSr55cXHpozG0hvOovqlifTwz9TER1IFe6RbR7dIFp1PosY8N8"
        />
        <h1>Background Verification</h1>
        {/* First Form ...... */}
        {currentStep === 1 && (
          <div>
            <div className="personal">
              <div
                className="h3 text-center mb-5"
                style={{
                  marginTop: "60px",
                  fontWeight: "bold",
                  fontSize: "34px",
                }}
              >
                CANDIDATE
                <span style={{ color: "rgb(255, 119, 0)" }}>
                  {" "}
                  PERSONAL
                </span>{" "}
                DETAILS
              </div>
              <div className="bg-light p-3 rounded-3 p-3 mx-auto border border-opacity-50">
                <div className="row ms-4 mb-4">
                  <div className="col-md-6">
                    <label htmlFor="name_of_applicant" className="form-label">
                      Name of the Applicant:
                    </label>
                    <br />
                    <input
                      className="inputtype bg-light mb-5 form-control"
                      list="recruiters"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      name="name_of_applicant"
                      maxLength="100"
                      required
                    />
                  </div>
                  <div className="col-md-6">
                    <label htmlFor="Input2" className="form-label">
                      Fathers/Husband/Wife Name
                    </label>
                    <br />
                    <input
                      className="inputtype bg-light mb-5 form-control"
                      list="projects"
                      value={fname}
                      onChange={(e) => setFname(e.target.value)}
                      name="fathers_name"
                      maxLength="100"
                      required
                    />
                  </div>
                </div>
                <div className="row ms-4 mb-4">
                  <div className="col-md-6">
                    <label htmlFor="Input3" className="form-label">
                      Mother's Name
                    </label>
                    <br />
                    <input
                      type="text"
                      className="inputtype bg-light mb-5 form-control"
                      value={mname}
                      onChange={(e) => setMname(e.target.value)}
                      name="mothers_name"
                      maxLength="100"
                    />
                  </div>
                  <div className="col-md-6">
                    <label htmlFor="Input4" className="form-label">
                      Applicant Gender
                    </label>{" "}
                    <br />
                    <select
                      className="inputtype bg-light mb-5 form-control"
                      value={gender}
                      onChange={(e) => setGender(e.target.value)}
                      name="gender"
                    >
                      <option value="">choose</option>
                      <option value="Male">Male</option>
                      <option value="Female">Female</option>
                      <option value="other">other</option>
                    </select>
                  </div>
                </div>
                <div className="row ms-4 mb-4">
                  <div className="col-md-6 input-icons">
                    <label htmlFor="Input5" className="form-label">
                      Date Of Birth
                    </label>
                    <br />
                    <div className="date-container">
                      <input
                        value={date}
                        onChange={(e) => setDate(e.target.value)}
                        type="date"
                        className="inputtype bg-light mb-5 form-control"
                        name="dob"
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <label htmlFor="Input6" className="form-label">
                      Email Id
                    </label>{" "}
                    <br />
                    <input
                      type="email"
                      className="inputtype bg-light mb-5 form-control"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      name="email"
                    />
                  </div>
                </div>
                <div className="row ms-4 mb-4">
                  <div className="col-md-6 input-icons">
                    <label htmlFor="Input5" className="form-label">
                      Contact Number
                    </label>
                    <br />
                    <div className="date-container">
                      <input
                        type="number"
                        className="inputtype bg-light mb-5 form-control"
                        value={number}
                        onChange={(e) => setNumber(e.target.value)}
                        name="contact_number"
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <label htmlFor="Input6" className="form-label">
                      Emergency Contact
                    </label>
                    <br />
                    <input
                      type="number"
                      className="inputtype bg-light mb-5 form-control"
                      value={enumber}
                      onChange={(e) => setEnumber(e.target.value)}
                      name="emergency_contact"
                    />
                  </div>
                </div>
                <div className="row ms-4 mb-4">
                  <div className="col-md-6">
                    <label htmlFor="Input7" className="form-label">
                      Company Name
                    </label>
                    <br />
                    <input
                      type="text"
                      className="inputtype bg-light mb-5 form-control"
                      value={companyname}
                      onChange={(e) => setCompanyName(e.target.value)}
                      name="name_of_client"
                      maxLength="100"
                    />
                  </div>
                  <div className="col-md-6">
                    <label htmlFor="Input7" className="form-label">
                      Company Location
                    </label>
                    <br />
                    <input
                      type="text"
                      className="bg-light mb-5 form-control"
                      value={location}
                      onChange={(e) => setLocation(e.target.value)}
                      name="client_location"
                      maxLength="30"
                    />
                  </div>
                </div>
                <div className="row ms-4 mb-4">
                  <div className="col-md-6">
                    <label htmlFor="Input7" className="form-label">
                      Client ID (Optional)
                    </label>
                    <br />
                    <input
                      type="text"
                      className="bg-light mb-5 form-control"
                      value={client_id}
                      onChange={(e) => setClientId(e.target.value)}
                      name="client_id"
                      maxLength="30"
                    />
                  </div>
                  <div className="col-md-6">
                    <label htmlFor="Input7" className="form-label">
                      Blood Group (optional)
                    </label>
                    <br />
                    <input
                      type="text"
                      className="bg-light mb-5 form-control"
                      value={blood}
                      onChange={(e) => setBlood(e.target.value)}
                      name="blood_group"
                      maxLength="5"
                    />
                  </div>
                </div>
                <div className="row ms-4 mb-4">
                  <h2>Permanent Address</h2>
                  <div className="col-md-6">
                    <label htmlFor="Input7" className="form-label">
                      Address Line 1
                    </label>
                    <br />
                    <input
                      type="text"
                      className="inputtype bg-light mb-5 form-control"
                      value={address1}
                      onChange={(e) => setAddress1(e.target.value)}
                      name="village_city"
                      maxLength="25"
                    />
                  </div>
                  <div className="col-md-6">
                    <label htmlFor="Input7" className="form-label">
                      Address Line 2 (optional upto 30 charector)
                    </label>
                    <br />
                    <input
                      type="text"
                      className=" bg-light mb-5 form-control"
                      value={address2}
                      onChange={(e) => setAddress2(e.target.value)}
                      name="village_city2"
                      maxLength="25"
                    />
                  </div>
                </div>
                <div className="row ms-4 mb-4">
                  <div className="col-md-6">
                    <label htmlFor="Input7" className="form-label">
                      Address Line 3 (optional)
                    </label>
                    <br />
                    <input
                      type="text"
                      className=" bg-light mb-5 form-control"
                      value={address3}
                      onChange={(e) => setAddress3(e.target.value)}
                      name="village_city3"
                      maxLength="25"
                    />
                  </div>
                  <div className="col-md-6">
                    <label htmlFor="Input7" className="form-label">
                      Post Office
                    </label>
                    <br />
                    <input
                      type="text"
                      className="inputtype bg-light mb-5 form-control"
                      value={post}
                      onChange={(e) => setPost(e.target.value)}
                      name="post_office"
                      maxLength="100"
                    />
                  </div>
                </div>
                <div className="row ms-4 mb-4">
                  <div className="col-md-6">
                    <label htmlFor="Input7" className="form-label">
                      Police Station/Thana
                    </label>
                    <br />
                    <input
                      type="text"
                      className="inputtype bg-light mb-5 form-control"
                      value={police}
                      onChange={(e) => setPolice(e.target.value)}
                      name="police_station"
                      maxLength="100"
                    />
                  </div>
                  <div className="col-md-6">
                    <label htmlFor="Input7" className="form-label">
                      District
                    </label>{" "}
                    <br />
                    <input
                      type="text"
                      className="inputtype bg-light mb-5 form-control"
                      value={district}
                      onChange={(e) => setDistrict(e.target.value)}
                      name="district"
                      maxLength="100"
                    />
                  </div>
                </div>

                <div className="row ms-4 mb-4">
                  <div className="col-md-6">
                    <label htmlFor="Input7" className="form-label">
                      Tehsil
                    </label>{" "}
                    <br />
                    <input
                      type="text"
                      className="inputtype bg-light mb-5 form-control"
                      value={tehsil}
                      onChange={(e) => setTehsil(e.target.value)}
                      name="tehsil"
                      maxLength="100"
                    />
                  </div>
                  <div className="col-md-6">
                    <label htmlFor="Input7" className="form-label">
                      Landmark
                    </label>{" "}
                    <br />
                    <input
                      type="text"
                      className="inputtype bg-light mb-5 form-control"
                      value={landmark}
                      onChange={(e) => setLandmark(e.target.value)}
                      name="landmark"
                      maxLength="100"
                    />
                  </div>
                </div>
                <div className="row ms-4 mb-4">
                  <div className="col-md-6">
                    <label htmlFor="Input7" className="form-label">
                      Pin Code
                    </label>{" "}
                    <br />
                    <input
                      type="number"
                      className="inputtype bg-light mb-5 form-control"
                      value={pincode}
                      onChange={(e) => setPincode(e.target.value)}
                      name="pine_code"
                    />
                  </div>
                  <div className="col-md-6">
                    <label htmlFor="Input7" className="form-label">
                      State
                    </label>{" "}
                    <br />
                    <input
                      type="text"
                      className="inputtype bg-light mb-5 form-control"
                      value={state}
                      onChange={(e) => setState(e.target.value)}
                      name="state"
                      maxLength="20"
                    />
                  </div>
                </div>

                <div className="row ms-4 mb-4 d-flex flex-row">
                  <label
                    style={{ width: "190px" }}
                    className="form-label"
                    htmlFor="same"
                  >
                    Same as above?
                  </label>
                  <input
                    style={{ width: "30px" }}
                    type="checkbox"
                    id="same"
                    name="same"
                    onChange={() => billingFunction()}
                  />
                </div>

                <div className="row ms-4 mb-4">
                  <h2>Current Address</h2>
                  <div className="col-md-6">
                    <label htmlFor="Input7" className="form-label">
                      Address Line 1
                    </label>
                    <br />
                    <input
                      type="text"
                      className="inputtype bg-light mb-5 form-control"
                      //   ={!isSameAddressChecked}
                      value={address11}
                      onChange={(e) => setAddress11(e.target.value)}
                      name="c_village_city"
                      maxLength="25"
                    />
                  </div>
                  <div className="col-md-6">
                    <label htmlFor="Input7" className="form-label">
                      Address Line 2(optional)
                    </label>
                    <br />
                    <input
                      type="text"
                      className=" bg-light mb-5 form-control"
                      //   ={!isSameAddressChecked}
                      value={address22}
                      onChange={(e) => setAddress22(e.target.value)}
                      name="c_village_city2"
                      maxLength="25"
                    />
                  </div>
                </div>
                <div className="row ms-4 mb-4">
                  <div className="col-md-6">
                    <label htmlFor="Input7" className="form-label">
                      Address Line 3 (optional)
                    </label>
                    <br />
                    <input
                      type="text"
                      className=" bg-light mb-5 form-control"
                      //   ={!isSameAddressChecked}
                      value={address33}
                      onChange={(e) => setAddress33(e.target.value)}
                      name="c_village_city3"
                      maxLength="25"
                    />
                  </div>
                  <div className="col-md-6">
                    <label htmlFor="Input7" className="form-label">
                      Post Office
                    </label>
                    <br />
                    <input
                      type="text"
                      className="inputtype bg-light mb-5 form-control"
                      //   ={!isSameAddressChecked}
                      value={post1}
                      onChange={(e) => setPost1(e.target.value)}
                      name="c_post_office"
                      maxLength="100"
                    />
                  </div>
                </div>
                <div className="row ms-4 mb-4">
                  <div className="col-md-6">
                    <label htmlFor="Input7" className="form-label">
                      Police Station
                    </label>
                    <br />
                    <input
                      type="text"
                      className="inputtype bg-light mb-5 form-control"
                      //   ={!isSameAddressChecked}
                      value={police1}
                      onChange={(e) => setPolice1(e.target.value)}
                      name="c_police_station"
                      maxLength="100"
                    />
                  </div>
                  <div className="col-md-6">
                    <label htmlFor="Input7" className="form-label">
                      District
                    </label>{" "}
                    <br />
                    <input
                      type="text"
                      className="inputtype bg-light mb-5 form-control"
                      value={district1}
                      onChange={(e) => setDistrict1(e.target.value)}
                      name="c_district"
                      maxLength="100"
                      //   ={!isSameAddressChecked}
                    />
                  </div>
                </div>
                <div className="row ms-4 mb-4">
                  <div className="col-md-6">
                    <label htmlFor="Input7" className="form-label">
                      Tehsil
                    </label>{" "}
                    <br />
                    <input
                      type="text"
                      className="inputtype bg-light mb-5 form-control"
                      value={tehsil1}
                      onChange={(e) => setTehsil1(e.target.value)}
                      name="c_tehsil"
                      maxLength="100"
                      //   ={!isSameAddressChecked}
                    />
                  </div>
                  <div className="col-md-6">
                    <label htmlFor="Input7" className="form-label">
                      Landmark
                    </label>{" "}
                    <br />
                    <input
                      type="text"
                      className="inputtype bg-light mb-5 form-control"
                      value={landmark1}
                      onChange={(e) => setLandmark1(e.target.value)}
                      name="c_landmark"
                      maxLength="100"
                      //   ={!isSameAddressChecked}
                    />
                  </div>
                </div>
                <div className="row ms-4 mb-4">
                  <div className="col-md-6">
                    <label htmlFor="Input7" className="form-label">
                      Pin Code
                    </label>{" "}
                    <br />
                    <input
                      type="number"
                      className="inputtype bg-light mb-5 form-control"
                      value={pincode1}
                      onChange={(e) => setPincode1(e.target.value)}
                      name="c_pine_code"
                      maxLength="100"
                      //   ={!isSameAddressChecked}
                    />
                  </div>
                  <div className="col-md-6">
                    <label htmlFor="Input7" className="form-label">
                      State
                    </label>{" "}
                    <br />
                    <input
                      type="text"
                      className="inputtype bg-light mb-5 form-control"
                      value={state1}
                      onChange={(e) => setState1(e.target.value)}
                      name="c_state"
                      maxLength="20"
                      //   ={!isSameAddressChecked}
                    />
                  </div>
                </div>
              </div>
              <div style={{ overflow: "auto" }}>
                <div style={{ float: "right" }}>
                  <button
                    type="button"
                    className="tablinks mb-2 ms-2"
                    onClick={nextStep1}
                  >
                    Next
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}

        {/* Second Form ...... */}
        {currentStep === 2 && (
          <div> 
            <div className="reference">
              <div
                className="h3 text-center mb-5"
                style={{
                  marginTop: "60px",
                  fontWeight: "bold",
                  fontSize: "34px",
                }}
              >
                CANDIDATE
                <span style={{ color: "rgb(255, 119, 0)" }}>
                  {" "}
                  REFERENCES
                </span>{" "}
                DETAILS
              </div>
              <div className="bg-light p-3 rounded-3 p-3 mx-auto border border-opacity-50">
                <hr className="ms-5 me-5" />
                <div className="ref1 mb-4 text-center">
                  <h4 style={{ color: "rgb(255, 119, 0)" }}>Add Reference 1</h4>
                  <h5>Give Reference of your Family or Relatives ONLY</h5>
                  <h4 style={{color:'red'}}>* Duplicate contact numbers are not allowed. *</h4>
                </div>
                <hr className="ms-5 me-5 mb-4" />

                <div className="row ms-4 mb-4">
                  <div className="col-md-6">
                    <label htmlFor="Input13" className="form-label">
                      Name
                    </label>{" "}
                    <br />
                    <input
                      type="text"
                      className="inputtype bg-light mb-5 form-control"
                      value={reference_1_name}
                      onChange={(e) => setReference_1_Name(e.target.value)}
                      name="ref_name1"
                      maxLength="100"
                    />
                  </div>
                  <div className="col-md-6">
                    <label htmlFor="Input14" className="form-label">
                      Relation
                    </label>{" "}
                    <br />
                    <input
                      type="text"
                      className="inputtype bg-light mb-5 form-control"
                      value={reference_1_relation}
                      onChange={(e) => setReference_1_Relation(e.target.value)}
                      name="ref_relation1"
                      maxLength="100"
                    />
                  </div>
                </div>
                <div className="row ms-4 mb-4">
                  <div className="col-md-6">
                    <label htmlFor="Input15" className="form-label">
                      Contact No.
                    </label>
                    <br />
                    <input
                      type="number"
                      className="inputtype bg-light mb-5 form-control"
                      value={reference_1_number}
                      onChange={(e) => setReference_1_Number(e.target.value)}
                      name="ref_conatact1"
                    />
                     {errors.reference_1_number && (
                      <div className="text-danger">
                        {errors.reference_1_number}
                      </div>
                    )}
                  </div>
                  <div className="col-md-6">
                    <label htmlFor="Input13" className="form-label">
                      Address
                    </label>{" "}
                    <br />
                    <input
                      type="text"
                      className="inputtype bg-light mb-5 form-control"
                      value={reference_1_address}
                      onChange={(e) => setReference_1_Address(e.target.value)}
                      name="ref_address1"
                      maxLength="100"
                    />
                  </div>
                </div>

                <hr className="ms-5 me-5" />
                <div className="ref2 mb-4 text-center">
                  <h4 style={{ color: "rgb(255, 119, 0)" }}>Add Reference 2</h4>
                  <h5>Give Reference of your Family or Relatives ONLY</h5>
                </div>
                <hr className="ms-5 me-5 mb-4" />
                <div className="row ms-4 mb-4">
                  <div className="col-md-6">
                    <label htmlFor="Input13" className="form-label">
                      Name
                    </label>{" "}
                    <br />
                    <input
                      type="text"
                      className="inputtype bg-light mb-5 form-control"
                      value={reference_2_name}
                      onChange={(e) => setReference_2_Name(e.target.value)}
                      name="ref_name2"
                      maxLength="100"
                    />
                  </div>
                  <div className="col-md-6">
                    <label htmlFor="Input14" className="form-label">
                      Relation
                    </label>{" "}
                    <br />
                    <input
                      type="text"
                      className="inputtype bg-light mb-5 form-control"
                      value={reference_2_relation}
                      onChange={(e) => setReference_2_Relation(e.target.value)}
                      name="ref_relation2"
                      maxLength="100"
                    />
                  </div>
                </div>
                <div className="row ms-4 mb-4">
                  <div className="col-md-6">
                    <label htmlFor="Input15" className="form-label">
                      Contact No.
                    </label>
                    <br />
                    <input
                      type="number"
                      className="inputtype bg-light mb-5 form-control"
                      value={reference_2_number}
                      onChange={(e) => setReference_2_Number(e.target.value)}
                      name="ref_contact2"
                    />
                     {errors.reference_2_number && (
                      <div className="text-danger">
                        {errors.reference_2_number}
                      </div>
                    )}
                  </div>
                  <div className="col-md-6">
                    <label htmlFor="Input13" className="form-label">
                      Address
                    </label>{" "}
                    <br />
                    <input
                      type="text"
                      className="inputtype bg-light mb-5 form-control"
                      value={reference_2_address}
                      onChange={(e) => setReference_2_Address(e.target.value)}
                      name="ref_address2"
                      maxLength="100"
                    />
                  </div>
                </div>
                <hr className="ms-5 me-5" />
                <div className="ref3 mb-4 text-center">
                  <h4 style={{ color: "rgb(255, 119, 0)" }}>Add Reference 3</h4>
                  <h5>Give Reference of Your Friend Or Colleague Only</h5>
                </div>
                <hr className="ms-5 me-5 mb-4" />
                <div className="row ms-4 mb-4">
                  <div className="col-md-6">
                    <label htmlFor="Input13" className="form-label">
                      Name
                    </label>{" "}
                    <br />
                    <input
                      type="text"
                      className="inputtype bg-light mb-5 form-control"
                      value={reference_3_name}
                      onChange={(e) => setReference_3_Name(e.target.value)}
                      name="ref_name3"
                      maxLength="100"
                    />
                  </div>
                  <div className="col-md-6">
                    <label htmlFor="Input14" className="form-label">
                      Relation
                    </label>{" "}
                    <br />
                    <input
                      type="text"
                      className="inputtype bg-light mb-5 form-control"
                      value={reference_3_relation}
                      onChange={(e) => setReference_3_Relation(e.target.value)}
                      name="ref_relation3"
                      maxLength="100"
                    />
                  </div>
                </div>
                <div className="row ms-4 mb-4">
                  <div className="col-md-6">
                    <label htmlFor="Input15" className="form-label">
                      Contact No.
                    </label>
                    <br />
                    <input
                      type="number"
                      className="inputtype bg-light mb-5 form-control"
                      value={reference_3_number}
                      onChange={(e) => setReference_3_Number(e.target.value)}
                      name="ref_contact3"
                    />
                     {errors.reference_3_number && (
                      <div className="text-danger">
                        {errors.reference_3_number}
                      </div>
                    )}
                  </div>
                  <div className="col-md-6">
                    <label htmlFor="Input13" className="form-label">
                      Address
                    </label>{" "}
                    <br />
                    <input
                      type="text"
                      className="inputtype bg-light mb-5 form-control"
                      value={reference_3_address}
                      onChange={(e) => setReference_3_Address(e.target.value)}
                      name="ref_address3"
                      maxLength="100"
                    />
                  </div>
                </div>
                <hr className="ms-5 me-5" />
                <div className="ref4 mb-4 text-center">
                  <h4 style={{ color: "rgb(255, 119, 0)" }}>Add Reference 4</h4>
                  <h5>Give Reference of Your Friend Or Colleague Only</h5>
                </div>
                <hr className="ms-5 me-5 mb-4" />
                <div className="row ms-4 mb-4">
                  <div className="col-md-6">
                    <label htmlFor="Input13" className="form-label">
                      Name
                    </label>{" "}
                    <br />
                    <input
                      type="text"
                      className="inputtype bg-light mb-5 form-control"
                      value={reference_4_name}
                      onChange={(e) => setReference_4_Name(e.target.value)}
                      name="ref_name4"
                      maxLength="100"
                    />
                  </div>
                  <div className="col-md-6">
                    <label htmlFor="Input14" className="form-label">
                      Relation
                    </label>{" "}
                    <br />
                    <input
                      type="text"
                      className="inputtype bg-light mb-5 form-control"
                      value={reference_4_relation}
                      onChange={(e) => setReference_4_Relation(e.target.value)}
                      name="ref_relation4"
                      maxLength="100"
                    />
                  </div>
                </div>
                <div className="row ms-4 mb-4">
                  <div className="col-md-6">
                    <label htmlFor="Input15" className="form-label">
                      Contact No.
                    </label>
                    <br />
                    <input
                      type="number"
                      className="inputtype bg-light mb-5 form-control"
                      value={reference_4_number}
                      onChange={(e) => setReference_4_Number(e.target.value)}
                      name="ref_contact4"
                    />
                     {errors.reference_4_number && (
                      <div className="text-danger">
                        {errors.reference_4_number}
                      </div>
                    )}
                  </div>
                  <div className="col-md-6">
                    <label htmlFor="Input13" className="form-label">
                      Address
                    </label>{" "}
                    <br />
                    <input
                      type="text"
                      className="inputtype bg-light mb-5 form-control"
                      value={reference_4_address}
                      onChange={(e) => setReference_4_Address(e.target.value)}
                      name="ref_address4"
                      maxLength="100"
                    />
                  </div>
                </div>
              </div>
              <div style={{ overflow: "auto" }}>
                <div style={{ float: "right" }}>
                  <button
                    type="button"
                    className="tablinks mb-2 ms-2"
                    style={{ backgroundColor: "#BBBBBB" }}
                    onClick={prevStep}
                  >
                    Previous
                  </button>
                  <button
                    type="button"
                    className="tablinks mb-2 ms-2"
                    onClick={nextStep2}
                  >
                    Next
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}

        {/* Third Form ...... */}
        {currentStep === 3 && (
          <div>
            <div className="document">
              <div
                className="h3 text-center mb-5"
                style={{
                  marginTop: "60px",
                  fontWeight: "bold",
                  fontSize: "34px",
                }}
              >
                CANDIDATE
                <span style={{ color: "rgb(255, 119, 0)" }}>
                  {" "}
                  DOCUMENTS{" "}
                </span>{" "}
                DETAILS
              </div>
              <h4 className=" text-center mb-2">
                Please Remove All Password From The Documents
              </h4>
              <h5 className=" text-center mb-5">
                <a href="https://www.ilovepdf.com/unlock_pdf">
                  Click here to Remove Password
                </a>
              </h5>
              <h3
                className=" text-center"
                style={{ color: "rgb(255, 119, 0)" }}
              >
                * Upload a Fully Clear and Completed Document *
              </h3>
              <h4 style={{ color: "red", textAlign: "center" }}>
                * use .jpg and .png format *
              </h4>
              <div className="bg-light p-3 rounded-3 p-3 mx-auto border border-opacity-50">
                <div className="row ms-4 mb-5">
                  <div className="col-md-4">
                    <label htmlFor="Input7" className="form-label">
                      Aadhar Card Number
                    </label>
                    <br />
                    <input
                      type="number"
                      className="inputtype bg-light mb-5 form-control"
                      value={aadhar}
                      onChange={(e) => setAadhar(e.target.value)}
                      name="aadhar_card"
                    />
                  </div>
                  <div className="col-md-4">
                    <label htmlFor="Input8" className="form-label">
                      Aadhar Card FRONT SIDE
                    </label>
                    <br />
                    <input
                      type="file"
                      className="inputtype bg-light mb-5 form-control file-upload"
                      // value={aadhar_front_side}
                      onChange={(e) =>
                        handleFileChange(e, setAadhar_Front_Side)
                      }
                      name="aadhar_front"
                      accept=".png, .jpg"
                    />
                  </div>
                  <div className="col-md-4">
                    <label htmlFor="Input9" className="form-label">
                      Aadhar Card BACK SIDE
                    </label>
                    <br />
                    <input
                      type="file"
                      className="inputtype bg-light mb-5 form-control file-upload"
                      // value={aadhar_back_side}
                      onChange={(e) => handleFileChange(e, setAadhar_Back_Side)}
                      name="aadhar_back"
                      accept=".png, .jpg"
                    />
                  </div>
                </div>
                <div className="row ms-4 mb-5">
                  <div className="col-md-4">
                    <label htmlFor="Input9" className="form-label">
                      Passport Size Photo
                    </label>
                    <br />
                    <input
                      type="file"
                      className="inputtype bg-light mb-5 form-control file-upload"
                      // value={passport_size_photo}
                      onChange={(e) =>
                        handleFileChange(e, setPassport_Size_Photo)
                      }
                      name="passport_photo"
                      accept=".png, .jpg"
                    />
                  </div>
                  <div className="col-md-4">
                    <label htmlFor="Input8" className="form-label">
                      Pan Card Number (Optional)
                    </label>
                    <br />
                    <input
                      type="text"
                      className=" bg-light mb-5 form-control"
                      value={pan_card_number}
                      onChange={(e) => setPan_Card_Number(e.target.value)}
                      name="pan_number"
                    />
                  </div>

                  <div className="col-md-4">
                    <label htmlFor="Input9" className="form-label">
                      Driving License (Optional)
                    </label>{" "}
                    <span style={{ color: "red", fontSize: "small" }}>
                      {" "}
                      (use .jpg and .png format)
                    </span>
                    <br />
                    <input
                      type="file"
                      className=" bg-light mb-5 form-control file-upload"
                      // value={pan_card}
                      onChange={(e) => handleFileChange(e, setDriving_License)}
                      name="driving_license"
                      accept=".png, .jpg"
                    />
                  </div>
                </div>
              </div>
              <div style={{ overflow: "auto" }}>
                <div style={{ float: "right" }}>
                  <button
                    type="button"
                    className="tablinks mb-2 ms-2"
                    style={{ backgroundColor: "#BBBBBB" }}
                    onClick={prevStep}
                  >
                    Previous
                  </button>
                  <button
                    type="button"
                    className="tablinks mb-2 ms-2"
                    onClick={nextStep3}
                  >
                    Next
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}

        {/* Fouth Form ...... */}
        {currentStep === 4 && (
          <div>
            <div className="tenancy">
              <div
                className="h3 text-center mb-5"
                style={{
                  marginTop: "60px",
                  fontWeight: "bold",
                  fontSize: "34px",
                }}
              >
                CANDIDATE
                <span style={{ color: "rgb(255, 119, 0)" }}> TENANCY</span>{" "}
                DETAILS
              </div>
              <div className="bg-light p-3 rounded-3 p-3 mx-auto border border-opacity-50">
                <div className="row ms-4 mb-4">
                  <div className="col-md-6 col-sm-12 mb-5">
                    <label>Tenancy</label>
                    <br />
                    <select
                      className="form-control mt-3"
                      name="choose_tenancy"
                      id="tenancy1"
                      onChange={() => {
                        hideTenancy();
                        setHouse_Owner_Name("");
                        setTenancy_Address("");
                        setTenancy_Number("");
                        setResiding_date("");
                      }}
                    >
                      <option value="">Choose</option>
                      <option value="Own House">Own House</option>
                      <option value="Rented">Rented</option>
                    </select>
                  </div>
                </div>
                {isOwnHouse ? (
                  <div>
                    <div className="row ms-4 mb-4">
                      <div className="col-md-6">
                        <label className="form-label">House Owner Name</label>
                        <br />
                        <input
                          type="text"
                          className="inputtype bg-light mb-5 form-control"
                          value={house_owner_name}
                          onChange={(e) => setHouse_Owner_Name(e.target.value)}
                          name="house_owner_name"
                        />
                      </div>
                      <div className="col-md-6">
                        <label className="form-label">Address</label> <br />
                        <input
                          type="text"
                          className="inputtype bg-light mb-5 form-control"
                          value={tenancy_address}
                          onChange={(e) => setTenancy_Address(e.target.value)}
                          name="tenancy_address"
                        />
                      </div>
                    </div>
                    <div className="row ms-4 mb-4">
                      <div className="col-md-6">
                        <label className="form-label">Contact Number</label>
                        <br />
                        <input
                          type="number"
                          className="inputtype bg-light mb-5 form-control"
                          value={tenancy_number}
                          onChange={(e) => setTenancy_Number(e.target.value)}
                          name="tenancy_number"
                        />
                      </div>
                      <div className="col-md-6 input-icons">
                        <label className="form-label">Residing Date</label>
                        <br />
                        <div className="date-container">
                          <input
                            value={residing_date}
                            onChange={(e) => setResiding_date(e.target.value)}
                            name="residing_date"
                            type="date"
                            className="inputtype bg-light mb-5 form-control"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  ""
                )}
                <div className="row ms-4 mb-4">
                  {isLoading ? (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        zIndex: "1",
                        flexDirection: "column",
                      }}
                    >
                      {" "}
                      <img src={img} alt="" />
                      <div style={{ color: "#DE3500", marginTop: "10px" }}>
                        Please Wait ......
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>

            <div style={{ overflow: "auto" }}>
              <div style={{ float: "right" }}>
                <button
                  type="button"
                  className="tablinks mb-2 ms-2"
                  style={{ backgroundColor: "#BBBBBB" }}
                  onClick={prevStep}
                  disabled={isLoading}
                >
                  Previous
                </button>
                <button
                  className="tablinks mb-2 ms-2"
                  onClick={nextStep4}
                  disabled={isLoading}
                >
                  Next
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
